import { default as indexbdAeImmzSMMeta } from "/opt/build/repo/nuxt-app/pages/index.vue?macro=true";
import { default as indexXP10nx1ANRMeta } from "/opt/build/repo/nuxt-app/pages/events/[id]/index.vue?macro=true";
import { default as rejoindreTtNqUKAl7kMeta } from "/opt/build/repo/nuxt-app/pages/events/[id]/rejoindre.vue?macro=true";
import { default as newUXuT44D4KoMeta } from "/opt/build/repo/nuxt-app/pages/events/new.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexbdAeImmzSMMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___en",
    path: "/en/events/:id()",
    meta: indexXP10nx1ANRMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id___fr",
    path: "/fr/events/:id()",
    meta: indexXP10nx1ANRMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-rejoindre___en",
    path: "/en/events/:id()/rejoindre",
    meta: rejoindreTtNqUKAl7kMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/events/[id]/rejoindre.vue").then(m => m.default || m)
  },
  {
    name: "events-id-rejoindre___fr",
    path: "/fr/events/:id()/rejoindre",
    meta: rejoindreTtNqUKAl7kMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/events/[id]/rejoindre.vue").then(m => m.default || m)
  },
  {
    name: "events-new___en",
    path: "/en/events/new",
    component: () => import("/opt/build/repo/nuxt-app/pages/events/new.vue").then(m => m.default || m)
  },
  {
    name: "events-new___fr",
    path: "/fr/events/new",
    component: () => import("/opt/build/repo/nuxt-app/pages/events/new.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexbdAeImmzSMMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexbdAeImmzSMMeta || {},
    component: () => import("/opt/build/repo/nuxt-app/pages/index.vue").then(m => m.default || m)
  }
]