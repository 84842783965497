import { getAnalytics, type Analytics } from "firebase/analytics";
import { getApps, initializeApp } from "firebase/app";
import { getMessaging, onMessage, type Messaging } from "firebase/messaging";

declare module "#app" {
  interface NuxtApp {
    $messaging: Messaging;
    $analytics: Analytics;
  }
}

declare module "vue" {
  interface ComponentCustomProperties {
    $messaging: Messaging;
    $analytics: Analytics;
  }
}

export default defineNuxtPlugin(() => {
  const app =
    getApps()[0] ??
    initializeApp({
      apiKey: "AIzaSyAofkWdqfigjm-BRkRdDA1_r0Otyl_35Ss",
      authDomain: "onsyvoit.firebaseapp.com",
      projectId: "onsyvoit",
      storageBucket: "onsyvoit.appspot.com",
      messagingSenderId: "853540710169",
      appId: "1:853540710169:web:8d53e775ef011399e14788",
      measurementId: "G-XBMHRP58XM",
    });

  const messaging = getMessaging(app);
  const analytics = getAnalytics(app);

  // This runs whenever a message is received:
  //   - When the page is open
  //   - When the user clicked the bg notification
  onMessage(messaging, (payload) => {
    // alert(JSON.stringify(payload, null, 2));
    // console.log(payload);
  });

  return {
    provide: {
      messaging,
      analytics,
    },
  };
});
