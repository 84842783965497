import { defineStore } from "pinia";

export const useUserStore = defineStore("useUserStore", () => {
  const user = useSupabaseUser();
  // Sentry.setUser({ id: user.id });

  return {
    user,
  };
});
