export default defineAppConfig({
  ui: {
    primary: "green",
    gray: "black",
    notifications: {
      position: "bottom-0 left-1/2 transform -translate-x-1/2",
    },
    formGroup: {
      label: {
        base: "font-medium text-black-800 font-xs",
      },
    },
    input: {
      size: {
        lg: "text-base",
      },
      rounded: "rounder-lg",
      placeholder: "placeholder-black-500",
      color: {
        black: {
          outline:
            "border-2 ring-2 ring-transparent focus:ring-black-950 focus:ring-2 border-none focus:shadow-custom4 error:ring-green-950",
        },
      },
      default: {
        size: "lg",
        color: "black",
        variant: "outline",
      },
    },
    textarea: {
      size: {
        lg: "text-base",
      },
      color: {
        black: {
          outline:
            "border-2 ring-2 ring-transparent focus:ring-black-950 focus:ring-2 border-none focus:shadow-custom4 error:ring-green-950",
        },
      },
      default: {
        size: "lg",
        color: "black",
        variant: "outline",
      },
    },
    button: {
      base: "text-center text-base  inline-flex items-center justify-center font-medium active:translate-x-0.5 active:translate-y-0.5 transition",
      padding: {
        lg: "px-5",
        md: "px-4",
      },
      size: {
        lg: "h-[45px]",
        md: "h-9",
      },

      rounded: "rounded-xl",
      color: {
        black: {
          solid:
            "bg-black-950 hover:bg-black-900  text-white  shadow-custom4 active:shadow-none ",
          outline:
            "bg-transparent hover:bg-black-50  text-black-950 border-2 border-black-950",
          soft: "bg-black-100 hover:bg-black-200 text-black-950",
          ghost: "bg-transparent hover:bg-black-50",
        },
        red: {
          solid:
            "bg-red-700 hover:bg-red-600  text-white  shadow-custom4 active:shadow-none ",
          outline:
            "bg-transparent hover:bg-red-50  text-red-950 border-2 border-red-950",
          soft: "bg-red-100 hover:bg-red-200 text-red-800",
          ghost: "bg-transparent hover:bg-red-50",
        },
      },
      default: {
        size: "lg",
        variant: "solid",
        color: "black",
        padding: "lg",
        loadingIcon: "i-material-symbols-sync",
      },
    },
    meterGroup: {
      list: "hidden",
    },
    popover: {
      overlay: {
        background: "bg-black-950/50 backdrop-blur-sm",
      },
    },
    toggle: {
      active: "bg-green-200 border-green-700",
      inactive: "bg-black-200 border-black-800",
      base: "border items-center",

      container: {
        base: "shadow-none",
        size: {
          sm: "ml-px",
        },
      },

      icon: {
        on: "text-green-700",
        off: "text-black-800",
        loading: "text-black-500",
      },

      default: {
        onIcon: "i-material-symbols:check-small",
        offIcon: "i-material-symbols:close-small",
      },
    },
  },
});
