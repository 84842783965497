import { getToken, isSupported } from "firebase/messaging";

import { useNuxtApp } from "#app";
import { defineStore } from "pinia";
import type { CreateFcmTokenSchema } from "~/server/api/fcm_tokens/index.post";

export const useNotificationsStore = defineStore("notifications", () => {
  const userStore = useUserStore();
  const { $messaging } = useNuxtApp();
  const { t } = useI18n();

  const isLoading = ref<boolean>(false);

  async function activateNotifications(): Promise<void> {
    isLoading.value = true;
    try {
      await requestUserBrowserPermissionForNotifications();
      const browserSupportNotifications =
        await checkIfBrowserPermissionForNotificationsGranted();
      if (browserSupportNotifications) {
        await saveCurrentDeviceFcmToken();
      }
    } catch (error) {
    } finally {
      isLoading.value = false;
    }

    return;
  }

  async function requestUserBrowserPermissionForNotifications(): Promise<void> {
    const browserSupportNotifications =
      await checkBrowserSupportNotifications();
    if (!browserSupportNotifications) {
      useToast().add({
        title: t("plugins.notifications.toasts.notSupported.title"),
        description: t("plugins.notifications.toasts.notSupported.description"),
      });
      return;
    }

    const permission = window.Notification.permission;

    switch (permission) {
      case "denied":
        useToast().add({
          title: t("plugins.notifications.toasts.permissionDenied.title"),
          description: t(
            "plugins.notifications.toasts.permissionDenied.description"
          ),
        });
        break;

      case "default":
        await window.Notification.requestPermission(async (value) => {
          if (value === "granted") {
            useToast().add({
              title: t(
                "plugins.notifications.toasts.notificationsActivated.title"
              ),
              description: t(
                "plugins.notifications.toasts.notificationsActivated.description"
              ),
            });
          }
        });
        break;

      default:
        break;
    }

    return;
  }

  async function saveCurrentDeviceFcmToken(): Promise<void> {
    if (userStore.user === null) return;

    const currentDeviceFcmToken = await getCurrentDeviceFcmToken();
    const { data, error } = await useFetch(`/api/fcm_tokens`, {
      method: "post",
      body: <CreateFcmTokenSchema>{
        fcmToken: currentDeviceFcmToken,
        userId: userStore.user.id,
      },
    });
  }

  async function getCurrentDeviceFcmToken(): Promise<string> {
    const fcmToken = await getToken($messaging, {
      vapidKey:
        "BPe-k1CIbMZJyNkzz1sZW-GRu7fSlrpzg1lQL71vnkZYPi1vdaF0z-vqyoAPHYVTKY-fX-UE0oDEITho3YmcmKM",
    });

    return fcmToken;
  }

  async function checkIfBrowserPermissionForNotificationsGranted(): Promise<boolean> {
    const browserSupportNotifications =
      await checkBrowserSupportNotifications();
    if (!browserSupportNotifications) return false;

    const supported = await isSupported();
    if (!supported) return false;

    return window.Notification.permission === "granted";
  }

  async function checkBrowserSupportNotifications(): Promise<boolean> {
    return isSupported().then((value) => {
      return "Notification" in window && value;
    });
  }
  return {
    checkIfBrowserPermissionForNotificationsGranted,
    isLoading,
    saveCurrentDeviceFcmToken,
    activateNotifications,
  };
});
